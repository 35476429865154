import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/apiServices/auth/authorization.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  listMenu = {
    shortcuts:[
      // {route:'dashboard',text:'Dashboard', icon:'../../../assets/images/icons/menu_dashboard.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'account',text:'Account', icon:'../../../assets/images/icons/menu_users.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'daily-report',text:'Daily Report', icon:'../../../assets/images/icons/menu_report.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'recharge',text:'Recharge', icon:'../../../assets/images/icons/menu_recharge.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'bkashtransaction',text:'Bkash Transaction', icon:'../../../assets/images/icons/menu_bkash.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'upaytransaction',text:'Upay Transaction', icon:'../../../assets/images/icons/menu_upay.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'water-transaction',text:'Water Transaction', icon:'../../../assets/images/icons/menu_water.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'card',text:'Card', icon:'../../../assets/images/icons/menu_card.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'wasa-recharge-request',text:'Recharge Request', icon:'../../../assets/images/icons/menu_recharge.svg', iconHeight:'35px', iconWidth:'35px'}
    ],
    reports:[
      {route:'report/atm-water-dispensed',text:'ATM wise water dispense', icon:'../../../assets/images/icons/atm.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'report/customer-water-dispensed',text:'Customer wise water dispense', icon:'../../../assets/images/icons/dispense.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'report/recharge-revenue',text:'Pump Recharge', icon:'../../../assets/images/icons/widraw.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'report/registered-customer',text:'New Cards Sold', icon:'../../../assets/images/icons/reg_customer.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'report/customer-recharge',text:'Customer Recharge', icon:'../../../assets/images/icons/customer_recharge.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'report/customer-dispense',text:'Customer Water Dispense', icon:'../../../assets/images/icons/menu_water.svg', iconHeight:'20px', iconWidth:'20px'},
    ],
    logs:[
      {route:'Log/DashboardAuditLog',text:'Dashboard Audit Log', icon:'../../../assets/images/icons/menu_dashboard.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'Log/UserHistory',text:'User History', icon:'../../../assets/images/icons/user_history.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'Log/DeviceLog',text:'Device Audit Log', icon:'../../../assets/images/icons/devices.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'Log/LoginLog',text:'Login Log', icon:'../../../assets/images/icons/enter.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'Log/BkashpayloadLog',text:'Bkash Pay Load Log', icon:'../../../assets/images/icons/menu_bkash.svg', iconHeight:'20px', iconWidth:'20px'},
      {route:'Log/UpaypayloadLog',text:'Upay Pay Load Log', icon:'../../../assets/images/icons/menu_upay.svg', iconHeight:'20px', iconWidth:'20px'},
    ],
    settings:[
      {route:'setting/zone',text:'Zone', icon:'../../../assets/images/icons/zone.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'setting/vendor',text:'Vendor', icon:'../../../assets/images/icons/businessman.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'setting/booth',text:'Pump', icon:'../../../assets/images/icons/pump.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'setting/device',text:'ATM', icon:'../../../assets/images/icons/atm.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'setting/cost',text:'Cost', icon:'../../../assets/images/icons/chart.svg', iconHeight:'35px', iconWidth:'35px'},
      {route:'setting/user',text:'User', icon:'../../../assets/images/icons/user.svg', iconHeight:'35px', iconWidth:'35px'},
     
    ]
  }

  //Index of list menu which user can access
  userAccess = {
    shortcuts:[],
    reports:[],
    logs:[],
    settings:[]
  }


  constructor(
    private router:Router,
    public authorizeService: AuthorizationService
    ) { }

  ngOnInit() {
    if(this.authorizeService.IsAdmin() || this.authorizeService.IsSuperAdmin()){
      this.userAccess.shortcuts = [0,1,2,3,4,5,6];
      this.userAccess.reports = [0,1,2,3,4,5];
      this.userAccess.logs = [0,1,2,3,4,5];
      this.userAccess.settings = [0,1,2,3,4,5];
    }

    if(this.authorizeService.IsGovtAdmin()){
      this.userAccess.shortcuts = [7];
      this.userAccess.reports = [];
      this.userAccess.logs = [];
      this.userAccess.settings = [];
    }

    if(this.authorizeService.IsPumpOperator()){
      this.userAccess.shortcuts = [0,2,6]
    }

    if(this.authorizeService.IsDataOperator()){
      this.userAccess.shortcuts = [0]
    }
  }

  navigateDashboard(){
    this.router.navigate(['dashboard']);
  }

}
