import { DashboardPumpDetailComponent } from './pages/dashboard-pump-detail/dashboard-pump-detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthGuard } from './apiServices/auth/auth.guard';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DashboardMainViewNewComponent } from './pages/dashboard-main-view-new/dashboard-main-view-new.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
    { path: "", redirectTo: "home", pathMatch: "full" },
    { path: "login", component: LoginComponent },
    {
        path: "",
        component: LayoutComponent,
        children: [
            {
                path: "home",
                component:HomeComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "dashboard",
        component: LayoutComponent,
        children: [
            {
                path: "",
                // component: DashboardMainViewComponent,
                component:DashboardMainViewNewComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "dashboard-pumpDetail/:pumpDetailID",
        component: LayoutComponent,
        children: [
            {
                path: "",
                component:DashboardPumpDetailComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "daily-report",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren:
                    "./moduleDailyReport/daily-report.module#DailyReportModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "wasa-recharge-request",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren:
                    "./moduleWasaRechargeRequest/wasa-recharge-request.module#WasaRechargeRequestModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "account",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./moduleAccount/account.module#AccountModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "recharge",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./moduleRecharge/recharge.module#RechargeModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "bkashtransaction",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./modulebkashtransaction/bkashtransaction.module#BkashTransactionModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "upaytransaction",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./moduleupaytransaction/upaytransaction.module#UpayTransactionModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "water-transaction",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren:
                    "./moduleWaterTransaction/water-transaction.module#WaterTransactionModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "card",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./moduleCard/card.module#CardModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "setting",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./moduleSetting/setting.module#SettingModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "Log",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./moduleLog/Log.module#LogModule",
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "report",
        component: LayoutComponent,
        children: [
            {
                path: "",
                loadChildren: "./moduleReport/report.module#ReportModule",
                canActivate: [AuthGuard]
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{useHash: true})
      ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
